import React from 'react';
import 'react-step-progress-bar/styles.css';
import { ProgressBar, Step } from 'react-step-progress-bar';
import { useMapContext } from '../Contexts/MapContext';

export default function StepProgressBar() {
  const { map } = useMapContext();

  return (
    <ProgressBar
      percent={map.progress}
      filledBackground="linear-gradient(to right, #fbefe9, #DB5B25)"
    >
      {/* <Step
        transition="scale"
        position={1}
      >
        {({ accomplished }) => (
          <img
            className={`${accomplished ? 'grayscale-0' : 'grayscale-[80%]'} ml-5`}
            width="30px"
            src={'https://emojipedia-us.s3.amazonaws.com/source/skype/289/round-pushpin_1f4cd.png'}
          />
        )}
      </Step>
      <Step
        transition="scale"
        position={2}
      >
        {({ accomplished }) => (
          <img
            className={`${accomplished ? 'grayscale-0' : 'grayscale-[80%]'}`}
            width="30px"
            src={'https://images.emojiterra.com/twitter/512px/1f4cf.png'}
          />
        )}
      </Step>
      <Step
        transition="scale"
        position={3}
      >
        {({ accomplished }) => (
          <img
            className={`${accomplished ? 'grayscale-0' : 'grayscale-[80%]'}`}
            width="30px"
            src={'https://creazilla-store.fra1.digitaloceanspaces.com/emojis/47058/artist-palette-emoji-clipart-xl.png'}
          />
        )}
      </Step>
      <Step
        transition="scale"
        position={4}
      >
        {({ accomplished }) => (
          <img
            className={`${accomplished ? 'grayscale-0' : 'grayscale-[80%]'}`}
            width="30px"
            src={'https://images.emojiterra.com/google/android-11/512px/1f4b5.png'}
          />
        )}
      </Step>
      <Step
        transition="scale"
        position={5}
      >
        {({ accomplished }) => (
          <img
            className={`${accomplished ? 'grayscale-0' : 'grayscale-[80%]'} mr-12`}
            width="45px"
            src={require('../assets/calendar.png')}
          />
        )}
      </Step> */}
    </ProgressBar>
  );
}
