export const fenceData = [
  {
    type: "New Fence Unstained",
    styles: [
      "Side-by-side w/ Cap & Trim",
      "Board-on-board w/ Cap & Trim",
      // "Dog Ear",
      // "Horizontal",
    ],
    colors: ["Cedar"],
    heightOptions: ["6 ft", "8 ft"],
  },
  {
    type: "New Fence Pre-stained",
    styles: [
      "Side-by-side w/ Cap & Trim",
      "Board-on-board w/ Cap & Trim",
      // "Dog Ear",
      // "Horizontal",
    ],
    colors: ["Dark Walnut - Ready Seal®"],
    heightOptions: ["6 ft", "8 ft"],
  },
  // {
  //   type: "Stain and Sealing Only",
  //   styles: [
  //     "Side-by-side w/ Cap & Trim",
  //     "Board-on-board w/ Cap & Trim",
  //     "Dog Ear",
  //     "Horizontal",
  //   ],
  //   colors: ["Dark Walnut - Ready Seal®"],
  //   heightOptions: ["6 ft", "7 ft", "8 ft"],
  // },
  // {
  //   type: "Wrought Iron",
  //   styles: ["2 Rail Steel", "3 Rail Steel"],
  //   colors: ["Black"],
  //   heightOptions: ["4 ft", "5 ft", "6 ft"],
  // },
];

export const fenceTypeImages = {
  "New Fence Unstained":
    "https://s3.amazonaws.com/homebaseimages/1014/d825cb00-19d1-4f03-837e-10100c5d6ed6/24d8abf5-204f-4da9-8119-7f12145a87beUnstained%20Wood%20Magnolia%20Fence.png",
  "New Fence Pre-stained":
    "https://s3.amazonaws.com/homebaseimages/1014/d825cb00-19d1-4f03-837e-10100c5d6ed6/c05f575c-5e36-4752-b415-310a1647354apre%20stained%20wood.png",
  "Stain and Sealing Only":
    "https://s3.amazonaws.com/homebaseimages/1014/d825cb00-19d1-4f03-837e-10100c5d6ed6/ca5da105-ae87-4501-81c8-4b936e05e46dpre%20stained%20wood.png",
  "Wrought Iron":
    "https://s3.amazonaws.com/homebaseimages/1014/d825cb00-19d1-4f03-837e-10100c5d6ed6/45ccf94a-55d0-4347-b932-f24365b4abecwrought%20iron%20magnolia%20fence.png",
};

export const fenceStyleImages = {
  "Side-by-side w/ Cap & Trim":
    "https://s3.amazonaws.com/homebaseimages/1014/d825cb00-19d1-4f03-837e-10100c5d6ed6/bdb6faa7-3d62-417a-b526-f21ef6724472CaliforniaPicFrame.png",
  "Board-on-board w/ Cap & Trim":
    "https://s3.amazonaws.com/homebaseimages/1014/d825cb00-19d1-4f03-837e-10100c5d6ed6/98936505-c8ba-479b-80f3-6a414f01aabfBoard%20on%20Board%20with%20Cap%20and%20Trim.png",

  "Dog Ear":
    "https://s3.amazonaws.com/homebaseimages/1014/d825cb00-19d1-4f03-837e-10100c5d6ed6/c690625c-d226-4bb8-9762-2dafa320da5dDogEarPrivacy.png",
  Horizontal:
    "https://s3.amazonaws.com/homebaseimages/1014/d825cb00-19d1-4f03-837e-10100c5d6ed6/bdb6faa7-3d62-417a-b526-f21ef6724472CaliforniaPicFrame.png",
  "2 Rail Steel":
    "https://s3.amazonaws.com/homebaseimages/1014/d825cb00-19d1-4f03-837e-10100c5d6ed6/f620049d-6759-4a04-88d1-dfb1be431d8ewrought%20iron%20magnolia%20fence.png",
  "3 Rail Steel":
    "https://s3.amazonaws.com/homebaseimages/1014/d825cb00-19d1-4f03-837e-10100c5d6ed6/89ae5c19-d946-494c-85be-5127c224bbe73%20rail%20wrought%20iron.png",
};

export const fenceColorImages = {
  "Dark Walnut - Ready Seal®":
    "https://s3.amazonaws.com/homebaseimages/1014/d825cb00-19d1-4f03-837e-10100c5d6ed6/bdb6faa7-3d62-417a-b526-f21ef6724472CaliforniaPicFrame.png",
  Cedar:
    "https://s3.amazonaws.com/homebaseimages/1014/d825cb00-19d1-4f03-837e-10100c5d6ed6/bdb6faa7-3d62-417a-b526-f21ef6724472CaliforniaPicFrame.png",
  Black:
    "https://s3.amazonaws.com/homebaseimages/1014/d825cb00-19d1-4f03-837e-10100c5d6ed6/a0017230-d9a5-4934-a7b1-36d909481f61Black.png",
};

export const fenceHeightOptionsImages = {
  "4 ft":
    "https://s3.amazonaws.com/homebaseimages/1014/d825cb00-19d1-4f03-837e-10100c5d6ed6/25ccff5e-1452-4d02-8685-38d7a5d3ede84%20ft.png",
  "5 ft":
    "https://s3.amazonaws.com/homebaseimages/1014/d825cb00-19d1-4f03-837e-10100c5d6ed6/4378de2d-c7f1-4578-8712-809c83e28ae85Foot.png",
  "6 ft":
    "https://s3.amazonaws.com/homebaseimages/1014/d825cb00-19d1-4f03-837e-10100c5d6ed6/e561d0ef-3d3e-4be2-b334-f5f3580eeb226Foot.png",
  "7 ft":
    "https://s3.amazonaws.com/homebaseimages/1014/d825cb00-19d1-4f03-837e-10100c5d6ed6/23035c47-5bf6-45a3-b8bd-41baf9aad43c7Foot.png",
  "8 ft":
    "https://s3.amazonaws.com/homebaseimages/1014/d825cb00-19d1-4f03-837e-10100c5d6ed6/5bf08064-f541-474f-b76f-89e1072a0e468Foot.png",
};

export const fencePrices = {
  "8 ft New Fence Unstained Side-by-side w/ Cap & Trim": 62,
  "8 ft New Fence Unstained Board-on-board w/ Cap & Trim": 66,
  //'8 ft New Fence Unstained Horizontal': 33,
  "6 ft New Fence Unstained Side-by-side w/ Cap & Trim": 36,
  "6 ft New Fence Unstained Board-on-board w/ Cap & Trim": 40,
  //'6 ft New Fence Unstained Horizontal': 21,

  "8 ft New Fence Pre-stained Side-by-side w/ Cap & Trim": 75,
  "8 ft New Fence Pre-stained Board-on-board w/ Cap & Trim": 79,
  //'8 ft New Fence Pre-stained Horizontal': 33,
  "6 ft New Fence Pre-stained Side-by-side w/ Cap & Trim": 42,
  "6 ft New Fence Pre-stained Board-on-board w/ Cap & Trim": 51,
  //'6 ft New Fence Pre-stained Horizontal': 26,

  // '8 ft Stain and Sealing Only Side-by-side w/ Cap & Trim': 10,
  // '8 ft Stain and Sealing Only Board-on-board w/ Cap & Trim': 10,
  // '8 ft Stain and Sealing Only Horizontal': 10,
  // '6 ft Stain and Sealing Only Side-by-side w/ Cap & Trim': 10,
  // '6 ft Stain and Sealing Only Board-on-board w/ Cap & Trim': 10,
  // '6 ft Stain and Sealing Only Horizontal': 10,

  // '6 ft Wrought Iron 2 Rail Steel': 30,
  // '6 ft Wrought Iron 3 Rail Steel': 35,
  // '5 ft Wrought Iron 2 Rail Steel': 28,
  // '5 ft Wrought Iron 3 Rail Steel': 30,
  // '4 ft Wrought Iron 2 Rail Steel': 32,
  // '4 ft Wrought Iron 3 Rail Steel': 26
};
