import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import MeasureTool from 'measuretool-googlemaps-v3';

import { useMapContext, setDistance, setMeasureTool, setSegments } from '../Contexts/MapContext';

export default function MeasureToolbar(props) {
  const location = useLocation();
  const { map, dispatch } = useMapContext();
  const [show, setShow] = useState(false);

  useEffect(() => {
    //setShow(['/draw-fence', '/draw-fence/'].includes(window.location.pathname));
    if (map.mapInstance) {
      dispatch(
        setMeasureTool(
          new MeasureTool(map.mapInstance, {
            contextMenu: false,
            showSegmentLength: true,
            tooltip: false,
            unit: MeasureTool.UnitTypeId.IMPERIAL // or just use 'imperial'
          })
        )
      );
    }
    //   map.measureTool.start();
    //   map.measureTool.addListener('measure_change', (e) => {
    //     console.log(e.result.length);
    //     dispatch(setDistance(Math.round(e.result.length)));
    //   });

    //   map.measureTool.addListener('measure_end', (e) => {
    //     console.log(e.result);
    //     //dispatch(setDistance(Math.round(e.result.length)));
    //   });

    // if (map.mapInstance && ['/draw-fence', '/draw-fence/'].includes(window.location.pathname)) {
    //   dispatch(
    //     setMeasureTool(
    //       new MeasureTool(map.mapInstance, {
    //         contextMenu: false,
    //         showSegmentLength: true,
    //         tooltip: false,
    //         unit: MeasureTool.UnitTypeId.IMPERIAL // or just use 'imperial'
    //       })
    //     )
    //   );

    //   map.measureTool.start();
    //   map.measureTool.addListener('measure_change', (e) => {
    //     console.log(e.result.length);
    //     dispatch(setDistance(Math.round(e.result.length)));
    //   });
    // }
    // if (map.mapInstance && !['/draw-fence', '/draw-fence/'].includes(window.location.pathname)) {
    //   map.measureTool.end();
    // }
    //return () => map.measureTool && map.measureTool.end();
    console.log('rendered');
  }, [map.mapInstance]);
  //   console.log(['/draw-fence'].includes(window.location.pathname));

  return (
    // <div className={`${!show && 'hidden'} bg-white h-20 rounded-md w-full`}>
    <div className={`hidden bg-white h-20 rounded-md w-full`}>
      <div className="h-full grid items-center">
        <div className="h-1/2 flex justify-evenly">
          {/* <button
            onClick={() => {
              measureTool.start();
            }}
            className="bg-slate-400 px-2"
          >
            Add Fence
          </button>
          <button
            onClick={() => measureTool.end()}
            className="bg-slate-400 px-2"
          >
            Add Gate
          </button> */}
        </div>
      </div>
    </div>
  );
}
