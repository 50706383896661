import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { PopupModal } from "react-calendly";
import { useMapContext, setProgress } from "../Contexts/MapContext";
import Header from "../Components/Header";
import moment from "moment";

export default function EstimateSummary() {
  const navigate = useNavigate();
  const { map, dispatch } = useMapContext();
  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState({
    name: "",
    phone: "",
    email: "",
    address: "",
  });
  let query = useQuery();

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(setProgress(85));
    console.log("rendered");
  }, [window.location.pathname]);

  function handleErrors(response) {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    return response;
  }

  async function submitForm(form) {
    setLoading(true);
    const response = await fetch(
      "https://hooks.zapier.com/hooks/catch/13158090/blb1k8m/",
      {
        method: "POST",
        headers: {
          Accept: "application/json",
        },
        body: JSON.stringify({
          ...form,
          address: query.get("address"),
          estimate: {
            numOfGates: query.get("numOfGates"),
            fenceType: `${query.get("fenceHeight")} ${query.get(
              "fenceType"
            )} ${query.get("fenceStyle")}`,
            fenceLength: query.get("fenceLength"),
            estimate: query.get("estimate"),
          },
        }),
      }
    )
      .then(handleErrors)
      .then((response) => response)
      .catch((error) => error);

    await delay(1000);
    setLoading(false);
    return response;
  }

  return (
    <div className='!w-[400px] bg-[#FFFFFF] shadow-black shadow-md z-10 relative h-full overflow-y-hidden'>
      <Header
        showBack={true}
        backUrl={-1}
        title={"Your Estimate"}
      />
      <div
        className={`w-full flex flex-col h-full overflow-auto scrollbar-thin !scrollbar-thumb-[#ed5421] !scrollbar-track-gray-100 md:overflow-y-hidden`}
      >
        <div className='px-10 overflow-auto scrollbar-thin !scrollbar-thumb-[#ed5421] !scrollbar-track-gray-100'>
          {/* <h2 className="text-left text-xl font-bold">Your estimate</h2> */}
          <div className='flex flex-col p-3 sm:pt-3 sm:p-8 pb-8 bg-[#F8F8F8] border border-solid border-gray-300 mt-4 shadow-sm'>
            <div className='grid grid-cols-3 gap-4'>
              <div className='col-span-2 flex-col flex'>
                <p className='text-md font-bold'>Texas Select Fencing</p>
                <a className='text-sm'>www.texasselectfencing.com</a>
                <a className='text-sm'>(214) 558-9169</a>
              </div>
              <div className='self-center h-full w-full flex justify-end mt-3'>
                <img
                  className='h-[35px] md:h-[55px]'
                  src={
                    "https://uploads-ssl.webflow.com/62c785fe02fc516f0ee97490/62c8a65f4df59bce4d3eac7d_texas-select-fencing-logo-with-background-2-p-500.webp"
                  }
                />
              </div>
            </div>

            <div className='flex flex-col justify-between h-72 pt-10'>
              <div className='flex justify-between'>
                <h3 className='font-bold'>Fence Type:</h3>
                <p className='text-right max-w-[60%]'>{`${query.get(
                  "fenceHeight"
                )} ${query.get("fenceType")} ${query.get("fenceStyle")}`}</p>
              </div>
              <div className='flex justify-between'>
                <h3 className='font-bold'>Fence Color:</h3>
                <p>{`${query.get("fenceColor")}`}</p>
              </div>
              <div className='flex justify-between'>
                <h3 className='font-bold'>Number of Gates:</h3>
                <p>{`${query.get("numOfGates")}`}</p>
              </div>
              <div className='flex justify-between'>
                <h3 className='font-bold'>Fence Length:</h3>
                <p>{`${query.get("fenceLength")} feet`}</p>
              </div>
              <div className='flex justify-between'>
                <h3 className='font-bold'>Total Estimate:</h3>
                <p className='text-[#ED5521] font-bold'>
                  {(query.get("estimate") * 0.9).toLocaleString("en-US", {
                    style: "currency",
                    currency: "USD",
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  })}
                  &nbsp;&nbsp;-&nbsp;&nbsp;
                  {(query.get("estimate") * 1).toLocaleString("en-US", {
                    style: "currency",
                    currency: "USD",
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  })}
                </p>
              </div>
            </div>
          </div>
          <div class='relative flex py-5 items-center'>
            <div class='flex-grow border-t border-gray-400'></div>
            <span class='flex-shrink mx-4 text-gray-400'>Next Steps</span>
            <div class='flex-grow border-t border-gray-400'></div>
          </div>
          <div className=''>
            <h2 className='font-bold text-lg'>Ready to move forward?</h2>
            <p className='pt-1'>
              If your estimate was within your budget and you would like to move
              forward, please fill out the form below and click "Submit" ⬇️
            </p>
          </div>
          <form
            name='myForm'
            onK
            onSubmit={async (e) => {
              e.preventDefault();
              dispatch(setProgress(100));
              await submitForm(form).then((res) => {
                if (res.ok) {
                  setLoading(false);
                  console.log("successs");
                  return (window.location.href =
                    "https://www.texasselectfencing.com/get-a-quote/success");
                  //   return navigate(
                  //     `/success?vehicleYear=${form.vehicleYear}&vehicleMake=${form.vehicleMake}&vehicleModel=${form.vehicleModel}&name=${
                  //       form.fullName.match(/[^\s]+/g)[0]
                  //     }`
                  //   );
                } else {
                  //   setLoading(false);
                  //   setError(true);
                  console.log("ERROR");
                }
              });
            }}
            className='w-full'
          >
            <div class='w-full flex justify-start items-center relative py-3'>
              <svg
                fill='#ED5521'
                class='absolute mr-2 ml-1 w-10'
                width='25pt'
                height='25pt'
                version='1.1'
                viewBox='0 0 752 752'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path d='m280.44 539.06c30.805 10.211 63.113 15.125 95.559 14.531 67.516 0 146.36-18.152 146.36-69.289-0.046875-29.352-8.9102-58.008-25.434-82.262-16.527-24.254-39.957-42.984-67.254-53.77 18.07-14.84 29.191-36.504 30.715-59.836 1.5234-23.336-6.6836-46.262-22.672-63.328-15.988-17.066-38.328-26.746-61.715-26.746-23.383 0-45.723 9.6797-61.711 26.746-15.988 17.066-24.195 39.992-22.672 63.328 1.5234 23.332 12.645 44.996 30.715 59.836-27.297 10.785-50.727 29.516-67.254 53.77-16.527 24.254-25.387 52.91-25.434 82.262 0 16.441 8.8125 39.297 50.797 54.758zm40.594-256.09c0-14.578 5.793-28.559 16.102-38.867 10.305-10.309 24.285-16.098 38.863-16.098s28.559 5.7891 38.867 16.098c10.309 10.309 16.098 24.289 16.098 38.867s-5.7891 28.559-16.098 38.867c-10.309 10.305-24.289 16.098-38.867 16.098-14.57-0.019531-28.543-5.8125-38.844-16.117-10.305-10.305-16.102-24.277-16.121-38.848zm54.965 84.562c30.957 0.042969 60.633 12.359 82.52 34.246 21.891 21.891 34.203 51.566 34.242 82.523 0 21.887-52.383 39.691-116.76 39.691s-116.76-17.805-116.76-39.691h0.003907c0.039062-30.957 12.352-60.633 34.242-82.523 21.887-21.887 51.562-34.203 82.516-34.246z' />{" "}
              </svg>
              <input
                onBlur={(e) => {
                  dispatch(setProgress(90));
                  setForm({ ...form, name: e.target.value });
                }}
                // autoFocus
                type='text'
                required
                placeholder='Full Name *'
                class='border border-gray-400 rounded-lg p-2 !pl-12 w-full placeholder:text-sm placeholder:text-gray-400 text-[16px] focus:border-[#ED5521] focus:ring-[#ED5521]'
              />
            </div>
            <div class='w-full flex justify-start items-center relative py-2'>
              <svg
                fill='#ED5521'
                class='absolute mr-2 ml-1 w-10'
                width='30pt'
                height='30pt'
                version='1.1'
                viewBox='0 0 752 752'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path d='m464.9 557.31c-2.875 0-5.7891-0.21094-8.7383-0.64844-54.145-7.9961-116.73-49.059-167.42-109.84-51.848-62.18-79.277-132.09-73.367-187.03 1.6562-15.445 8.625-28.23 20.699-38.008l17.875-14.477c24.301-19.68 60.082-15.918 79.766 8.3828l21.129 26.094c15.578 19.242 13.629 47.566-4.4453 64.484-10.332 9.6758-12.332 34.996-3.6289 45.922l46.68 58.598c8.7461 10.973 34.625 14.527 46.145 6.332 20.363-14.477 48.633-10.605 64.359 8.8086l20.852 25.754c19.68 24.301 15.918 60.082-8.3828 79.758l-16.285 13.188c-10.344 8.375-22.387 12.684-35.234 12.684zm-175.3-339.96c-7.5273 0-15.102 2.4805-21.395 7.5742l-17.875 14.477c-7.3555 5.9531-11.418 13.414-12.43 22.82-11.746 109.21 124.08 257.63 221.57 272.03 9.9531 1.4648 18.582-0.89844 26.398-7.2305l16.285-13.188c14.59-11.816 16.848-33.297 5.0312-47.887l-20.852-25.754c-8.2109-10.145-22.977-12.164-33.609-4.6016-21.66 15.402-60.523 10.008-77.004-10.676l-46.68-58.598c-16.074-20.172-13.109-58.82 5.8672-76.59 9.4375-8.832 10.457-23.625 2.3203-33.676l-21.129-26.094c-6.7188-8.2969-16.57-12.605-26.496-12.605z' />
              </svg>
              <input
                onBlur={(e) => {
                  dispatch(setProgress(93));
                  setForm({ ...form, phone: e.target.value });
                }}
                onKeyDown={() => phoneNumberFormatter()}
                type='tel'
                id='phone-number'
                name='phone'
                required={true}
                pattern='^(\([0-9]{3}\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$'
                onInvalid={(e) =>
                  e.target.setCustomValidity(
                    "Please enter a valid phone number"
                  )
                }
                onInput={(e) => e.target.setCustomValidity("")}
                placeholder='Phone Number *'
                class='border border-gray-400 rounded-lg p-2 !pl-12 w-full placeholder:text-sm placeholder:text-gray-400 text-[16px] focus:border-[#ED5521] focus:ring-[#ED5521]'
              />
            </div>
            <div class='w-full flex justify-start items-center relative py-2'>
              <svg
                fill='#ED5521'
                class='absolute mr-2 ml-1 w-10'
                xmlns='http://www.w3.org/2000/svg'
                width='25pt'
                height='25pt'
                version='1.1'
                viewBox='0 0 752 752'
              >
                <path d='m566.95 234.91h-381.91c-18.746 0-33.996 15.25-33.996 34v214.19c0 18.75 15.254 34 33.996 34h381.91c18.746 0 33.996-15.254 33.996-34l0.003906-214.19c0-18.75-15.254-34-34-34zm-12.309 21.422-155.16 102.77c-14.27 9.4258-32.707 9.4219-46.957 0.007813l-155.17-102.78zm24.883 226.77c0 6.9336-5.6406 12.578-12.574 12.578h-381.91c-6.9336 0-12.574-5.6406-12.574-12.578v-214.19c0-1.0703 0.13672-2.1094 0.38672-3.1016l167.85 111.17c10.719 7.082 23.004 10.621 35.289 10.621 12.289 0 24.578-3.543 35.309-10.629l167.84-111.16c0.25391 0.99219 0.38672 2.0312 0.38672 3.1016z' />
              </svg>
              <input
                onBlur={(e) => {
                  dispatch(setProgress(97));
                  setForm({ ...form, email: e.target.value });
                }}
                type='email'
                onInvalid={(e) =>
                  e.target.setCustomValidity("Please enter a valid email")
                }
                onInput={(e) => e.target.setCustomValidity("")}
                pattern='^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$'
                id='email'
                name='email'
                required
                placeholder='Email *'
                class='border border-gray-400 rounded-lg p-2 !pl-12 w-full placeholder:text-sm placeholder:text-gray-400 text-[16px] focus:border-[#ED5521] focus:ring-[#ED5521]'
              />
            </div>
            <div class='w-full flex justify-start items-center relative py-2 pb-5'>
              <svg
                fill='#ED5521'
                class='absolute mr-2 ml-1 w-10'
                width='25pt'
                height='25pt'
                version='1.1'
                viewBox='0 0 752 752'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path d='m376 181.83c-75.977 0-137.34 64.062-137.34 142.07 0 28.133 8.4727 50.523 21.605 71.777l103.6 167.68h0.003907c2.5781 4.2344 7.1758 6.8203 12.133 6.8203 4.9609 0 9.5586-2.5859 12.137-6.8203l103.6-167.68c13.133-21.254 21.605-43.645 21.605-71.777 0-78.012-61.363-142.07-137.34-142.07zm0 28.414c60.031 0 108.92 50.434 108.92 113.66 0 22.77-5.9688 38.227-17.465 56.828l-91.457 148-91.457-148c-11.496-18.605-17.465-34.059-17.465-56.828 0-63.227 48.895-113.66 108.92-113.66zm0 37.887c-39.062 0-71.039 31.973-71.039 71.035s31.973 71.039 71.039 71.039 71.039-31.973 71.039-71.039c0-39.062-31.973-71.035-71.039-71.035zm0 28.414c23.707 0 42.621 18.914 42.621 42.621 0 23.707-18.914 42.621-42.621 42.621s-42.621-18.914-42.621-42.621c0-23.707 18.914-42.621 42.621-42.621z' />
              </svg>
              <input
                onBlur={(e) => {
                  setForm({ ...form, address: e.target.value });
                }}
                defaultValue={query.get("address")}
                type='text'
                required
                placeholder='Address *'
                class='border border-gray-400 rounded-md p-2 !pl-12 w-full placeholder:text-sm placeholder:text-gray-400 text-[16px] focus:border-[#ED5521] focus:ring-[#ED5521]'
              />
            </div>
          </form>
        </div>
        <footer className='w-full bg-[#F8F8F8] border-t border-[#737373] py-5 z-50 h-28 min-h-[100px] flex flex-row justify-center mt-[auto] mb-[60px]'>
          <button
            disabled={!form.name || !form.email || !form.phone}
            className={`w-10/12 min-h-[63px] rounded-md text-white uppercase font-semibold text-lg tracking-wide my-1 ease-in-out duration-1000 bg-[#ED5521] ${
              loading && "hidden"
            } ${
              form.name && form.email && form.phone
                ? "bg-[#ED5521] bg-opacity-100"
                : "bg-opacity-30"
            }`}
            onClick={() => {
              document.forms["myForm"].requestSubmit();
              console.log(document.forms["myForm"]);
            }}
          >
            Submit
          </button>
          <button
            className={`flex flex-col self-center items-center w-10/12 sm:w-1/2 lg:w-1/3 rounded-md text-[#ED5521] uppercase font-semibold text-lg tracking-wide my-1 ${
              !loading && "hidden"
            } ease-in-out	duration-1000`}
            tabindex='0'
            //type="submit"
          >
            <div
              className='spinner-border animate-spin inline-block w-7 h-7 border-4 rounded-full'
              role='status'
            >
              <span class='visually-hidden'>Loading...</span>
            </div>
          </button>
        </footer>
      </div>
    </div>
  );
}

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function formatPhoneNumber(value) {
  if (!value) return value;
  const phoneNumber = value.replace(/[^\d]/g, "");
  const phoneNumberLength = phoneNumber.length;
  if (phoneNumberLength < 4) return phoneNumber;
  if (phoneNumberLength < 7) {
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
  }
  return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
    3,
    6
  )}-${phoneNumber.slice(6, 9)}`;
}

function phoneNumberFormatter() {
  const inputField = document.getElementById("phone-number");
  const formattedInputValue = formatPhoneNumber(inputField.value);
  inputField.value = formattedInputValue;
}
const delay = (ms) => {
  //console.log('sleeping')
  return new Promise((res) => setTimeout(res, ms));
};
// {/* <div className="flex flex-col justify-between h-72">
// <div className="pt-5">
//   <h3 className="font-bold">Address</h3>
//   <p>{map.location.address.match(/^[^,]*/g)[0]}</p>
// </div>
// <div className="">
//   <h3 className="font-bold">Fence Type</h3>
//   <p className="">{`${query.get('fenceHeight')} ${query.get('fenceType')} ${query.get('fenceStyle')}`}</p>
// </div>
// <div className="">
//   <h3 className="font-bold">Fence Color</h3>
//   <p>{`${query.get('fenceColor')}`}</p>
// </div>
// <div className="">
//   <h3 className="font-bold">Number of Gates</h3>
//   <p>{`${query.get('numOfGates')}`}</p>
// </div>
// <div className="">
//   <h3 className="font-bold">Fence Length</h3>
//   <p>{`${query.get('fenceLength')} ft`}</p>
// </div>
// <div className="">
//   <h3 className="font-bold">Total Estimate</h3>
//   <p>{`${query.get('estimate')}`}</p>
// </div>
// </div> */}
