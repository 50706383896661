import React from 'react';
import ReactDOM from 'react-dom';
//import axios from 'axios';
import './index.css';
import 'tw-elements';
import App from './App';
import { MapProvider } from './Contexts/MapContext';

// Find all widget divs
const widgetDivs = document.querySelectorAll('.jdela-solutions-widget');

// Inject our React App into each class
widgetDivs.forEach((div) => {
  ReactDOM.render(
    <React.StrictMode>
      <MapProvider>
        <App />
      </MapProvider>
    </React.StrictMode>,
    div
  );
});
