import React, { Component, useState, useEffect } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import Magnifier from 'react-magnifier';
import GoogleMapReact from 'google-map-react';
import { renderToString } from 'react-dom/server';
import { useMapContext, setMapsApiLoaded, setMapInstance, setMapsApi, setLocation, setZoom } from '../Contexts/MapContext';
import MeasureToolbar from './MeasureToolbar';

export default function Map() {
  const { map, dispatch } = useMapContext();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (searchParams.get('lng') && searchParams.get('lat')) {
      dispatch(setLocation({ lat: parseFloat(searchParams.get('lat')), lng: parseFloat(searchParams.get('lng')), address: searchParams.get('add') }));
      dispatch(setZoom(21));
    }
  }, [map.mapsApi, window.location.pathname]);

  const handleApiLoaded = (map, maps) => {
    console.log('fired');
    if (map && maps) {
      console.log('set maps');
      dispatch(setMapsApiLoaded(true));
      dispatch(setMapInstance(map));
      dispatch(setMapsApi(maps));
    }
  };

  return (
    // Important! Always set the container height explicitly
    <div
      id="map"
      className={`flex flex-col flex-grow overflow-auto h-full md:flex md:flex-col md:flex-grow md:overflow-auto ${
        !['/draw-fence', '/draw-fence/'].includes(window.location.pathname) && 'pointer-events-none'
      }`}
    >
      <GoogleMapReact
        // onBoundsChange={(center, zoom) => {
        //   console.log(zoom);
        // }}
        bootstrapURLKeys={{ key: 'AIzaSyCJ_mDLudxNumoUY4X7qqZ8eaezqjMUne0', libraries: ['places', 'geometry'] }}
        yesIWantToUseGoogleMapApiInternals
        center={map.location}
        zoom={map.zoom}
        options={(map) => ({
          mapTypeId: map.MapTypeId.HYBRID,
          tilt: 0,
          //scrollwheel: false,
          panControl: false,
          scaleControl: false,
          mapTypeControl: false,
          zoomControl: false,
          fullscreenControl: false,
          minZoom: 12,
          keyboardShortcuts: false,
          disableDefaultUI: true
          //draggable: false
        })}
        onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
      ></GoogleMapReact>
      <div className={`bg-none w-[calc(100%-400px)] fixed top-10 px-10 h-20 z-20 `}>
        <MeasureToolbar />
      </div>
    </div>
  );
}

// `<div class="magnifier " style="width: 500px; height: auto; overflow: visible;">
//   <img class="magnifier-image" src="https://uploads-ssl.webflow.com/62c785fe02fc516f0ee97490/62c8a65f4df59bce4d3eac7d_texas-select-fencing-logo-with-background-2-p-500.webp" width="100%" height="100%">
//   <div class="magnifying-glass circle" style="width: 150px; height: 150px; left: calc((88.8% - 75px + 0px) - 2px); top: calc((65.4762% - 75px + 0px) - 2px); background-image: url(&quot;https://uploads-ssl.webflow.com/62c785fe02fc516f0ee97490/62c8a65f4df59bce4d3eac7d_texas-select-fencing-logo-with-background-2-p-500.webp&quot;); background-position: calc((88.8% + 75px) - 133.2px) calc((65.4762% + 75px) - 98.2143px); background-size: 750% 378%; border-width: 2px;"></div>
// </div>;`;
