import React, { useEffect, useRef } from 'react';
import { useNavigate, useSearchParams, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

import { useMapContext, setLocation, setZoom, setProgress } from '../Contexts/MapContext';

const SearchBox = (props) => {
  const { dispatch } = useMapContext();
  const autoCompleteRef = useRef();
  const inputRef = useRef(null);
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const { search } = useLocation();

  useEffect(() => {
    autoCompleteRef.current = new window.google.maps.places.Autocomplete(inputRef.current);
    console.log(autoCompleteRef);
    autoCompleteRef.current.addListener('place_changed', async function () {
      const place = await autoCompleteRef.current.getPlace();
      console.log(place.formatted_address);

      dispatch(setLocation({ lat: place.geometry.location.lat(), lng: place.geometry.location.lng(), address: place.formatted_address }));
      dispatch(setProgress(25));

      //setSearchParams({ lat: place.geometry.location.lat(), lng: place.geometry.location.lng(), add: place.formatted_address });
      //navigate({ pathname: `/draw-fence/`, search: search });
      navigate(`/draw-fence/?lat=${place.geometry.location.lat()}&lng=${place.geometry.location.lng()}&add=${place.formatted_address}`);
      //console.log(searchParams);
      dispatch(setZoom(21));
    });

    // return () => {
    //   google.maps.event.clearInstanceListeners(searchBox);
    // };
  }, []);

  return (
    <input
      autocomplete="off"
      ref={inputRef}
      {...props}
      type="text"
    />
  );
};

SearchBox.propTypes = {
  placeholder: PropTypes.string,
  onPlacesChanged: PropTypes.func
};

export default SearchBox;
