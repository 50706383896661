import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams, useLocation } from "react-router-dom";

import {
  useMapContext,
  setProgress,
  setDistance,
  setSegments,
  setLocation,
  setZoom,
  setMeasureTool,
} from "../Contexts/MapContext";
import Map from "../Components/Map";
import Header from "../Components/Header";

const {
  fenceData,
  fencePrices,
  fenceTypeImages,
  fenceStyleImages,
  fenceColorImages,
  fenceHeightOptionsImages,
} = require("../data/fenceData");

const DrawFence = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const { map, dispatch } = useMapContext();
  const [activeKey, setActiveKey] = useState(null);
  const [timer, setTimer] = useState({ id: null, active: true });
  const [loading, setLoading] = useState(false);
  const [numOfGates, setNumOfGates] = useState(
    null || searchParams.get("numOfGates")
  );
  const [fenceType, setFenceType] = useState(
    null || searchParams.get("fenceType")
  );
  const [fenceStyle, setFenceStyle] = useState(
    null || searchParams.get("fenceStyle")
  );
  const [fenceColor, setFenceColor] = useState(
    null || searchParams.get("fenceColor")
  );
  const [fenceHeight, setFenceHeight] = useState(
    null || searchParams.get("fenceHeight")
  );
  const [estimate, setEstimate] = useState(
    null || searchParams.get("estimate")
  );

  useEffect(async () => {
    console.log(map.distance);

    if (map.distance === 0) window.scrollTo(0, 0);
    map.measureTool && map.measureTool.start(map.segments || null);
    fenceType && fenceStyle && fenceColor && fenceHeight && calculateEstimate();
    fenceType &&
      fenceStyle &&
      fenceColor &&
      fenceHeight &&
      dispatch(setProgress(75));

    map.measureTool &&
      map.measureTool.addListener("measure_change", async (e) => {
        dispatch(setProgress(40));
        clearTimeout(timer.id);
        //setTimer({ id: null, active: false });
        e.result.length !== 0 &&
          dispatch(setDistance(Math.round(e.result.length)));
        e.result.length !== 0 &&
          setTimer({
            id: setTimeout((res) => {
              setTimer({ ...timer, active: false });
            }, 3500),
            active: true,
          });
        //e.result.length !== 0 && dispatch(setDistance(Math.round(e.result.length)));
      });
    map.measureTool &&
      map.measureTool.addListener("measure_start", (e) => {
        console.log(map.measureTool);
        console.log("measure_start");
        //map.measureTool.setOption('segments', map.segments);
        //if (map.segments) map.measureTool.setOption('start', map.segments);
        //dispatch(setDistance(Math.round(e.result.length)));
      });
    map.measureTool &&
      map.measureTool.addListener("measure_end", (e) => {
        console.log(map.measureTool);
        console.log(e.result.points);
        dispatch(setSegments(e.result.points));
      });
  }, [
    fenceType,
    fenceStyle,
    fenceColor,
    fenceHeight,
    numOfGates,
    map.mapInstance,
    map.measureTool,
    timer,
  ]);

  async function handleButtonClick(e) {
    setNumOfGates(parseInt(e.target.value));
    setActiveKey("fenceType");
    await delay(10);
    searchParams.set("numOfGates", parseInt(e.target.value));
    setSearchParams(searchParams);
    document.getElementById("flush-collapseOne-button").click();
    dispatch(setProgress(50));
  }

  async function handleFenceSelection(type, data) {
    switch (type) {
      case "fenceType":
        console.log(data);
        setFenceType(data.type);
        setFenceStyle(null);
        setFenceColor(null);
        setFenceHeight(null);

        searchParams.set("fenceType", data.type);
        setSearchParams(searchParams);
        await delay(10);
        document.getElementById("flush-collapseOne-button2").click();
        setActiveKey(type);
        dispatch(setProgress(55));
        break;
      case "fenceStyle":
        setFenceStyle(data);
        await delay(10);
        searchParams.set("fenceStyle", data);
        setSearchParams(searchParams);
        document.getElementById("flush-collapseOne-button3").click();
        dispatch(setProgress(65));
        setActiveKey(type);
        break;
      case "fenceColor":
        setFenceColor(data);
        await delay(10);
        searchParams.set("fenceColor", data);
        setSearchParams(searchParams);
        document.getElementById("flush-collapseOne-button4").click();
        dispatch(setProgress(70));
        setActiveKey(type);
        break;
      case "fenceHeight":
        setFenceHeight(data);
        await delay(10);
        searchParams.set("fenceHeight", data);
        setSearchParams(searchParams);
        document.getElementById("flush-collapseOne-button4").click();
        dispatch(setProgress(75));
        break;
      default:
        break;
    }
    console.log(
      numOfGates,
      fenceType["type"],
      fenceStyle,
      fenceColor,
      fenceHeight
    );
  }

  async function calculateEstimate() {
    setLoading(true);
    await delay(250);
    let product = `${fenceHeight} ${fenceType} ${fenceStyle}`;
    setEstimate(fencePrices[product] * map.distance + numOfGates * 300);
    searchParams.set(
      "estimate",
      fencePrices[product] * map.distance + numOfGates * 300
    );
    setSearchParams(searchParams);
    setLoading(false);
  }

  return (
    <div className='w-full md:!w-[400px] bg-[#FFFFFF] shadow-black shadow-md z-10 relative h-full overflow-y-hidden'>
      <Header
        backUrl={"/"}
        callback={() => {
          dispatch(
            setLocation({ lat: 33.08271, lng: -96.66945, address: null })
          );
          dispatch(setZoom(12));
          map.measureTool.end();
          dispatch(setSegments(null));

          return dispatch(setProgress(0));
        }}
        showBack={true}
        title={"Measure & Style"}
      />
      <div
        className={`w-full flex flex-col h-full overflow-auto scrollbar-thin !scrollbar-thumb-[#ed5421] !scrollbar-track-gray-100`}
      >
        <div className='px-10 py-5'>
          <h2 className='text-left text-xl font-bold'>
            {window.innerWidth >= 768 ? "Click" : " Tap"} to start outlining
            your fence
          </h2>
          <p>Fence length: {map.distance} feet</p>
          <div
            className={`${
              timer.active &&
              "!mt-0 md:pt-5 invisible h-0 !opacity-0 md:!visible md:!h-auto md:!opacity-100"
            } opacity-100 ease-in-out duration-1000 mt-5`}
          >
            <h2
              className={`text-left text-xl font-bold ${
                (map.distance === 0 || null) && "!text-gray-300"
              }`}
            >
              How many gates do you need?
            </h2>
            <div className='flex justify-between my-2'>
              {Array(5)
                .fill(1)
                .map((el, i) => (
                  <button
                    disabled={map.distance === 0 || null}
                    value={i}
                    onClick={handleButtonClick}
                    className={`${
                      parseInt(numOfGates) === i &&
                      "!border-[#ED5521] !text-[#ED5521] hover:!bg-white hover:!border-[#ED5521] !font-bold"
                    } bg-none px-3 py-1 text-gray-500 rounded-md border-2 border-gray-500 hover:text-[#ED5521] hover:border-[#ED5521] transition duration-150 disabled:text-gray-300 disabled:border-gray-300 disabled:cursor-not-allowed`}
                  >
                    {i}
                  </button>
                ))}
            </div>
          </div>
          <div
            className={`${
              (numOfGates === null || numOfGates === NaN) &&
              "!mt-0 md:pt-5 invisible h-0 !opacity-0 md:!visible md:!h-auto md:!opacity-100"
            } opacity-100 ease-in-out duration-1000 mt-5`}
          >
            <h2
              className={`text-left text-xl font-bold ${
                numOfGates === null && "!text-gray-300"
              }`}
            >
              Design your fence
            </h2>
            <div className='flex flex-col'>
              <div
                disabled
                class='accordion accordion-flush'
                id='accordionFlushExample'
              >
                <div class='accordion-item border-t-0 border-l-0 border-r-0 rounded-none bg-white border border-gray-200'>
                  <h2
                    class='accordion-header mb-0'
                    id='flush-headingOne'
                  >
                    <button
                      disabled={numOfGates === null || numOfGates === NaN}
                      class='accordion-button relative flex items-center w-full py-4 px-0 text-base !text-black font-medium text-left bg-white border-0 rounded-none transition focus:outline-none disabled:!text-gray-300'
                      type='button'
                      data-bs-toggle='collapse'
                      data-bs-target='#flush-collapseOne'
                      aria-expanded='false'
                      aria-controls='flush-collapseOne'
                      id='flush-collapseOne-button'
                    >
                      {fenceType && (
                        <img
                          className='inline relative mr-3'
                          src={fenceTypeImages[fenceType]}
                          width='40pt'
                          height='40pt'
                        />
                      )}
                      {fenceType ? `${fenceType}` : "Choose fence type"}
                    </button>
                  </h2>
                  <div
                    id='flush-collapseOne'
                    class={`accordion-collapse border-0 collapse`}
                    aria-labelledby='flush-headingOne'
                    data-bs-parent='#accordionFlushExample'
                  >
                    <div class='accordion-body py-4 px-5 h-1/4'>
                      {fenceData.map((data, index) => {
                        return (
                          <p key={data.type + index}>
                            {data.type}{" "}
                            <img
                              className={`hover:cursor-pointer ${
                                fenceType &&
                                fenceType !== data.type &&
                                "opacity-40 saturate-0"
                              }`}
                              src={fenceTypeImages[data.type]}
                              onClick={() =>
                                handleFenceSelection("fenceType", data)
                              }
                            />
                          </p>
                        );
                      })}
                    </div>
                  </div>
                </div>
                <div class='accordion-item border-t-0 border-l-0 border-r-0 rounded-none bg-white border border-gray-200'>
                  <h2
                    class='accordion-header mb-0'
                    id='flush-headingTwo'
                  >
                    <button
                      disabled={!fenceType}
                      class='accordion-button relative flex items-center w-full py-4 px-0 text-base !text-black font-medium text-left bg-white border-0 rounded-none transition focus:outline-none disabled:!text-gray-300'
                      type='button'
                      data-bs-toggle='collapse'
                      data-bs-target='#flush-collapseTwo'
                      aria-expanded='false'
                      aria-controls='flush-collapseTwo'
                      id='flush-collapseOne-button2'
                    >
                      {fenceStyle && (
                        <img
                          className='inline relative mr-3'
                          src={fenceStyleImages[fenceStyle]}
                          width='40pt'
                          height='40pt'
                        />
                      )}
                      {fenceStyle ? `${fenceStyle}` : "Choose fence style"}
                    </button>
                  </h2>
                  <div
                    id='flush-collapseTwo'
                    class={`accordion-collapse border-0 collapse`}
                    aria-labelledby='flush-headingTwo'
                    data-bs-parent='#accordionFlushExample'
                  >
                    <div class='accordion-body py-4 px-5 h-1/4'>
                      {fenceType &&
                        fenceData
                          .filter((e) => e.type === fenceType)[0]
                          .styles.map((data) => {
                            return (
                              <p key={data}>
                                {data}{" "}
                                <img
                                  className={`hover:cursor-pointer ${
                                    fenceStyle &&
                                    fenceStyle !== data &&
                                    "opacity-40 saturate-0"
                                  }`}
                                  src={fenceStyleImages[data]}
                                  onClick={() =>
                                    handleFenceSelection("fenceStyle", data)
                                  }
                                />
                              </p>
                            );
                          })}
                    </div>
                  </div>
                </div>
                <div class='accordion-item border-t-0 border-l-0 border-r-0 rounded-none bg-white border border-gray-200'>
                  <h2
                    class='accordion-header mb-0'
                    id='flush-headingThree'
                  >
                    <button
                      disabled={!fenceStyle}
                      class='accordion-button relative flex items-center w-full py-4 px-0 text-base !text-black font-medium text-left bg-white border-0 rounded-none transition focus:outline-none disabled:!text-gray-300'
                      type='button'
                      data-bs-toggle='collapse'
                      data-bs-target='#flush-collapseThree'
                      aria-expanded='false'
                      aria-controls='flush-collapseThree'
                      id='flush-collapseOne-button3'
                    >
                      {fenceColor && (
                        <img
                          className='inline relative mr-3'
                          src={fenceColorImages[fenceColor]}
                          width='40pt'
                          height='40pt'
                        />
                      )}
                      {fenceColor ? `${fenceColor}` : "Choose fence color"}
                    </button>
                  </h2>
                  <div
                    id='flush-collapseThree'
                    class={`accordion-collapse border-0 collapse`}
                    aria-labelledby='flush-headingThree'
                    data-bs-parent='#accordionFlushExample'
                  >
                    <div class='accordion-body py-4 px-5 h-1/4'>
                      {fenceType &&
                        fenceData
                          .filter((e) => e.type === fenceType)[0]
                          .colors.map((data) => {
                            return (
                              <p key={data}>
                                {data}{" "}
                                <img
                                  className={`hover:cursor-pointer ${
                                    fenceColor &&
                                    fenceColor !== data &&
                                    "opacity-40 saturate-0"
                                  }`}
                                  src={fenceColorImages[data]}
                                  onClick={() =>
                                    handleFenceSelection("fenceColor", data)
                                  }
                                />
                              </p>
                            );
                          })}
                    </div>
                  </div>
                </div>
                <div class='accordion-item border-t-0 border-l-0 border-r-0 rounded-none bg-white border border-gray-200'>
                  <h2
                    class='accordion-header mb-0'
                    id='flush-headingFour'
                  >
                    <button
                      disabled={!fenceColor}
                      class='accordion-button relative flex items-center w-full py-4 px-0 text-base !text-black font-medium text-left bg-white border-0 rounded-none transition focus:outline-none disabled:!text-gray-300'
                      type='button'
                      data-bs-toggle='collapse'
                      data-bs-target='#flush-collapseFour'
                      aria-expanded='false'
                      aria-controls='flush-collapseFour'
                      id='flush-collapseOne-button4'
                    >
                      {fenceHeight && (
                        <img
                          className='inline relative mr-3'
                          src={fenceHeightOptionsImages[fenceHeight]}
                          width='40pt'
                          height='40pt'
                        />
                      )}
                      {fenceHeight ? `${fenceHeight}` : "Choose fence height"}
                    </button>
                  </h2>
                  <div
                    id='flush-collapseFour'
                    class={`accordion-collapse border-0 collapse`}
                    aria-labelledby='flush-headingFour'
                    data-bs-parent='#accordionFlushExample'
                  >
                    <div class='accordion-body py-4 px-5 h-1/4'>
                      {fenceType &&
                        fenceData
                          .filter((e) => e.type === fenceType)[0]
                          .heightOptions.map((data) => {
                            return (
                              <p key={data}>
                                {data}{" "}
                                <img
                                  className={`hover:cursor-pointer ${
                                    fenceHeight &&
                                    fenceHeight !== data &&
                                    "opacity-40 saturate-0"
                                  }`}
                                  src={fenceHeightOptionsImages[data]}
                                  onClick={() =>
                                    handleFenceSelection("fenceHeight", data)
                                  }
                                />
                              </p>
                            );
                          })}
                    </div>
                  </div>
                </div>
              </div>
              <div className='flex mt-6 justify-between w-full overflow-hidden'>
                <h2 className={`flex font-bold text-lg self-center`}>
                  Total estimate:
                </h2>
                <div className={`flex font-bold text-lg self-center`}>
                  {numOfGates &&
                  fenceStyle &&
                  fenceColor &&
                  fenceHeight &&
                  map.distance ? (
                    <h2
                      className={`self-center ${
                        loading && "hidden"
                      } text-[#ED5521] font-bold`}
                    >
                      {(estimate ? estimate * 0.9 : 0).toLocaleString("en-US", {
                        style: "currency",
                        currency: "USD",
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0,
                      })}
                      &nbsp;&nbsp;-&nbsp;&nbsp;
                      {(estimate ? estimate : 0).toLocaleString("en-US", {
                        style: "currency",
                        currency: "USD",
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0,
                      })}
                    </h2>
                  ) : (
                    <h2
                      className={`self-center ${
                        loading && "hidden"
                      } text-[#ED5521] font-bold`}
                    >
                      {(0).toLocaleString("en-US", {
                        style: "currency",
                        currency: "USD",
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0,
                      })}
                    </h2>
                  )}
                  <button
                    disabled={loading}
                    type='submit'
                    className={`flex flex-col self-center w-10/12 sm:w-1/2 lg:w-1/3 rounded-md text-[#ED5521] uppercase font-semibold text-lg tracking-wide my-1 ${
                      !loading && "hidden"
                    } ease-in-out	duration-1000`}
                    tabindex='0'
                    //type="submit"
                  >
                    <div
                      className='spinner-border animate-spin inline-block w-7 h-7 border-4 rounded-full'
                      role='status'
                    >
                      <span class='visually-hidden'>Loading...</span>
                    </div>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className={`h-full ${
            numOfGates && map.distance && "hidden"
          } md:hidden`}
        >
          {window.innerWidth <= 768 && <Map />}
        </div>
        <footer className='w-full bg-[#F8F8F8] border-t border-[#737373] py-5 z-50 h-28 min-h-[100px] flex flex-row justify-center mt-[auto] mb-[60px]'>
          <button
            id='wrapupestimate'
            className={`w-10/12 min-h-[63px] bg-[#ED5521] rounded-md text-white uppercase font-semibold text-lg tracking-wide my-1 ease-in-out duration-1000 ${
              fenceType && fenceStyle && fenceColor && fenceHeight
                ? "bg-[#ED5521] bg-opacity-100"
                : "bg-opacity-30"
            }`}
            disabled={
              fenceType
                ? fenceColor
                  ? fenceHeight
                    ? false
                    : true
                  : true
                : true
            }
            tabindex='0'
            onClick={() => {
              navigate(
                `/estimate-summary/?numOfGates=${numOfGates}&fenceType=${fenceType}&fenceStyle=${fenceStyle}&fenceColor=${fenceColor}&fenceHeight=${fenceHeight}&estimate=${estimate}&fenceLength=${map.distance}&address=${map.location.address}`
              );
              //map.measureTool && map.measureTool.end();
            }}
          >
            Wrap up estimate
          </button>
        </footer>
      </div>
    </div>
  );
};

export default DrawFence;

const delay = (ms) => {
  //console.log('sleeping')
  return new Promise((res) => setTimeout(res, ms));
};
