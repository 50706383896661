import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';

import Map from './Components/Map';
import YourAddress from './Pages/YourAddress';
import DrawFence from './Pages/DrawFence';
import ProgressBar from './Components/ProgressBar';
import EstimateSummary from './Pages/EstimateSummary';

export default function App() {
  return (
    <div className="theme-dark flex h-full relative">
      <Router>
        <Routes>
          <Route
            exact
            path="/"
            element={<YourAddress />}
          />
          <Route
            exact
            path="/draw-fence"
            element={<DrawFence />}
          />
          <Route
            exact
            path="/estimate-summary"
            element={<EstimateSummary />}
          />
        </Routes>
        <footer class="w-screen z-50 fixed bottom-0">
          <ProgressBar />
        </footer>
        <div className={`md:flex flex-col flex-grow overflow-auto h-full hidden`}>{window.innerWidth >= 768 && <Map />}</div>
      </Router>
    </div>
  );
}
