import React, { createContext, useReducer, useContext } from 'react';

export const MapContext = createContext();

// Initial state
const INITIAL_STATE = {
  mapsApiLoaded: false,
  mapInstance: null,
  mapsApi: null,
  location: { lat: 33.08271, lng: -96.66945, address: null },
  zoom: 12,
  distance: 0,
  progress: 0,
  measureTool: null,
  segments: null
};

// Actions
export const SET_MAPS_API_LOADED = 'SET_MAPS_API_LOADED';
export const SET_MAP_INSTANCE = 'SET_MAP_INSTANCE';
export const SET_MAPS_API = 'SET_MAPS_API';
export const SET_LOCATION = 'SET_LOCATION';
export const SET_ZOOM = 'SET_ZOOM';
export const SET_DISTANCE = 'SET_DISTANCE';
export const SET_PROGRESS = 'SET_PROGRESS';
export const SET_MEASURE_TOOL = 'SET_MEASURE_TOOL';
export const SET_SEGMENTS = 'SET_SEGMENTS';

// Action creators
export function setMapsApiLoaded() {
  return { type: SET_MAPS_API_LOADED };
}
export function setMapInstance(data) {
  console.log(data);
  return { type: SET_MAP_INSTANCE, data };
}
export function setMapsApi(data) {
  return { type: SET_MAPS_API, data };
}
export function setLocation(data) {
  return { type: SET_LOCATION, data };
}
export function setZoom(data) {
  return { type: SET_ZOOM, data };
}
export function setDistance(data) {
  console.log(data);
  return { type: SET_DISTANCE, data };
}
export function setProgress(data) {
  return { type: SET_PROGRESS, data };
}
export function setMeasureTool(data) {
  console.log(data);
  return { type: SET_MEASURE_TOOL, data };
}
export function setSegments(data) {
  console.log(data);
  return { type: SET_SEGMENTS, data };
}

// Reducer
export function mapReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case SET_MAPS_API_LOADED:
      console.log(action.type);
      return { ...state, mapsApiLoaded: true };
    case SET_MAP_INSTANCE:
      return { ...state, mapInstance: action.data };
    case SET_MAPS_API:
      return { ...state, mapsApi: action.data };
    case SET_LOCATION:
      return { ...state, location: { lat: action.data.lat, lng: action.data.lng, address: action.data.address } };
    case SET_ZOOM:
      return { ...state, zoom: action.data };
    case SET_DISTANCE:
      return { ...state, distance: action.data };
    case SET_PROGRESS:
      return { ...state, progress: action.data };
    case SET_MEASURE_TOOL:
      return { ...state, measureTool: action.data };
    case SET_SEGMENTS:
      return { ...state, segments: action.data };
    default:
      return state;
  }
}

function MapProvider(props) {
  const [map, dispatch] = useReducer(mapReducer, INITIAL_STATE);

  const mapData = { map, dispatch };

  return (
    <MapContext.Provider
      value={mapData}
      {...props}
    />
  );
}

function useMapContext() {
  return useContext(MapContext);
}

export { MapProvider, useMapContext };
