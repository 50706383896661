import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Magnifier from 'react-magnifier';
import { useMapContext } from '../Contexts/MapContext';
import SearchBox from '../Components/SearchBox';

const YourAddress = () => {
  const { map } = useMapContext();

  return (
    <div className="w-full md:!w-[400px] bg-[#FFFFFF] shadow-black shadow-md z-10 relative">
      <div className="w-full md:w-3/7 h-2xl bg-onPrimaryBg rounded-md relative px-10 top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2">
        <h2 className="text-left text-xl font-bold">What is your address?</h2>
        {
          <SearchBox
            className="w-full mt-3 rounded-md"
            placeholder={'Enter your address'}
            map={map.mapInstance}
            mapsapi={map.mapsApi}
          />
        }
      </div>
    </div>
  );
};

export default YourAddress;
