import React from "react";
import { useNavigate, useLocation } from "react-router-dom";

export default function Header(props) {
  const navigate = useNavigate();

  return (
    // <header class='bg-[#F8F8F8] h-auto sticky top-0 border-b border-[#737373] pt-1'>
    <header class='bg-white h-auto sticky top-0'>
      <div class='flex items-center mx-auto'>
        <div class='flex items-center pl-5'>
          {props.showBack ? (
            <svg
              onClick={() => {
                return (
                  navigate(props.backUrl), props.callback && props.callback()
                );
              }}
              fill='#ED5521'
              className='cursor-pointer inline relative w-[40px]'
              xmlns='http://www.w3.org/2000/svg'
              version='1.1'
              viewBox='0 0 752 752'
            >
              <path d='m549.05 361.79h-312.04l101.54-102.48c2.8047-2.6445 4.418-6.3125 4.4648-10.164 0.050782-3.8555-1.4688-7.5625-4.207-10.277-2.7383-2.7109-6.4609-4.1953-10.312-4.1094-3.8516 0.085937-7.5078 1.7344-10.121 4.5625l-125.5 126.68c-5.4766 5.5352-5.4766 14.449 0 19.984l125.5 126.68c2.6133 2.832 6.2695 4.4805 10.121 4.5664 3.8516 0.085937 7.5742-1.3984 10.312-4.1133 2.7383-2.7109 4.2578-6.418 4.207-10.273-0.046874-3.8555-1.6602-7.5234-4.4648-10.164l-101.54-102.48h312.04c7.8477 0 14.207-6.3594 14.207-14.207 0-7.8438-6.3594-14.207-14.207-14.207z' />
            </svg>
          ) : (
            <div className='w-10'></div>
          )}
        </div>
        {/* <div className='w-full mx-auto text-center'>
          <p class='text-xl font-bold titlecase'>{props.title}</p>
        </div>
        <div className='w-[60px]'></div> */}
        {/* <div>
          <img
            className='h-8 pr-3 cursor-pointer'
            src={
              "https://uploads-ssl.webflow.com/62c785fe02fc516f0ee97490/62c8a65f4df59bce4d3eac7d_texas-select-fencing-logo-with-background-2-p-500.webp"
            }
            onClick={() =>
              (window.location.href = "https://www.texasselectfencing.com")
            }
          />
        </div> */}
      </div>
      {/* <div class='flex justify-center content-center mb-1'></div> */}
    </header>
  );
}
